import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div>
            {/* SEO Metadata */}
            <Helmet>
                <title>EasyShare - Fast and Secure File Sharing</title>
                <meta
                    name="description"
                    content="Share files quickly and securely with EasyShare. No login required. Share large files with friends and colleagues in just a few steps."
                />
                <meta
                    name="keywords"
                    content="file sharing, secure file sharing, fast file transfer, share files online, large file sharing"
                />
                <meta name="author" content="EasyShare" />
            </Helmet>

            {/* Hero Section */}
            <header className="text-center bg-light py-5">
                <div className="container">
                    <h1 className="display-4 fw-bold">Welcome to EasyShare</h1>
                    <p className="lead">
                        The easiest and fastest way to share files securely with anyone, anywhere.
                    </p>
                    <button
                        className="btn btn-primary btn-lg mt-3"
                        onClick={() => navigate("/share")}
                    >
                        Start Sharing
                    </button>
                </div>
            </header>

            {/* Steps Section */}
            <section className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">How It Works</h2>
                    <div className="row text-center">
                        <div className="col-md-4">
                            <div className="card shadow p-3">
                                <h4 className="fw-bold">Step 1</h4>
                                <p>Upload your file to get a secure, shareable link.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow p-3">
                                <h4 className="fw-bold">Step 2</h4>
                                <p>Share the link with your friends or colleagues.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow p-3">
                                <h4 className="fw-bold">Step 3</h4>
                                <p>Download file. Happy share</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-4">Why Choose EasyShare?</h2>
                    <ul className="list-unstyled text-center">
                        <li className="mb-3"><strong>Speed:</strong> Upload and share files in seconds.</li>
                        <li className="mb-3"><strong>Security:</strong> Files are encrypted and automatically deleted after download.</li>
                        <li className="mb-3"><strong>No Login:</strong> Share files without creating an account.</li>
                        <li><strong>Unlimited File Types:</strong> Share any type of file, large or small.</li>
                    </ul>
                </div>
            </section>

            {/* Footer */}
            <footer className="text-center py-3 bg-dark text-light">
                <p>© {new Date().getFullYear()} EasyShare. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default HomePage;