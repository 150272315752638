import React, { useEffect } from "react";
import { downloadFile } from "../utils/api";

const DownloadFile = () => {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const fileName = query.get("file_name");
        const sessionSignature = query.get("session_signature");

        if (fileName && sessionSignature) {
            (async () => {
                try {
                    // Step 1: Trigger backend API to get presigned download URL
                    const { download_url } = await downloadFile(fileName, sessionSignature);

                    // Step 2: Automatically trigger file download
                    window.location.href = download_url;
                } catch (error) {
                    console.error("Failed to download file:", error.message);
                }
            })();
        }
    }, []);

    return <div>Downloading your file...</div>;
};

export default DownloadFile;