import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import FileUploader from "./components/FileUploader";
import DownloadFile from "./components/DownloadFile";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/share" element={<FileUploader />} />
                <Route path="/recieve" element={<DownloadFile />} />
            </Routes>
        </Router>
    );
};

export default App;