const BASE_URL = "https://shareasy.in/api"; // Replace with your backend's URL

// Generate a presigned URL for uploading
export const generatePresignedUrl = async (fileName) => {
    const response = await fetch(`${BASE_URL}/upload`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_name: fileName }),
    });

    if (!response.ok) {
        throw new Error("Failed to generate presigned URL");
    }

    return response.json();
};

// Download a file using the unique link
export const downloadFile = async (fileName, sessionSignature) => {
    console.log("sending req")
    const response = await fetch(`${BASE_URL}/download`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_name: fileName, session_signature: sessionSignature }),
    });

    if (!response.ok) {
        throw new Error("Failed to download file");
    }

    return response.json();
};